.footerhome {
  overflow: hidden;
  background: var(--background);
  padding: 1rem 1.5rem 1rem;
  color: var(--text-title);
  font-size: 1rem;
  line-height: 22px;
}

a.topo,
a.topo:hover,
a.topo:visited,
a.topo:focus {
  color: var(--text-title);
  text-decoration: none;
  outline: 0;
}
