#login {
  color: #ff5200;
  border-color: #ff5200;
  background-color: transparent;
}

#login:hover,
#register {
  color: #f5f5f5;
  border-color: #f5f5f5;
  background-color: #ff5200;
}

#register:hover {
  opacity: 0.7;
}

nav.navbar {
  min-height: 70px;
  box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
}

#topo.section {
  padding: 35px 0;
}

.section-hero {
  color: #f5f5f5;
  min-height: 100vh;
  background: url("./images/bg-photo.png") no-repeat;
  background-size: cover;
  /* margin-top: 70px; */
}

.hero .body {
  padding: 1rem 3rem;
}

.section-hero p {
  text-align: center;
}

.section-hero h1 {
  color: #f5f5f5;
  text-align: center;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.125;
  margin: 1.5rem 0;
}

.section-hero h1 strong {
  font-weight: bold;
}

.section-hero strong {
  color: #f5f5f5;
}

.img-ativo-16 {
  width: 10em;
}

.icon-app {
  width: 7em;
  /* border-radius */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

/* p.icon-app {
  margin-top: -2rem;
} */

.img-app {
  max-width: 80%;
  height: auto;
  outline: 0 !important;
  /* border-radius */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  /* box-shadow */
  -webkit-box-shadow: rgba(0, 0, 0, 0.8) 0 0 30px;
  -moz-box-shadow: rgba(0, 0, 0, 0.8) 0 0 30px;
  box-shadow: rgba(0, 0, 0, 0.8) 0 0 30px;
}

.texto-app {
  font-size: 24px;
  text-align: center;
  margin-bottom: 2.5rem;
}

.img-store {
  padding: 0.5rem;
  width: 11em;
  max-width: 11em;
}

.logo-home {
  max-height: 50px;
}

.footer {
  overflow: hidden;
  background: #242526;
  padding: 1rem 1.5rem 1rem;
  color: #e9e9e9;
  font-size: 1rem;
  line-height: 22px;
}

.img-ativo-192x {
  max-width: 8em;
}

a.back-to-top,
a.back-to-top:hover,
a.back-to-top:visited,
a.back-to-top:focus {
  color: #e9e9e9;
  text-decoration: none;
  outline: 0;
}

.question {
  background-color: #e8edf1;
}

.question p,
.asked-questions p {
  text-align: justify;
  margin-bottom: 2.5rem;
}

/* .asked-questions {
  background-color: #fff;
} */
